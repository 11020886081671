import React from 'react'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import Player from '../components/Player'
import { copyToClipboard } from '../utils/index'

import { FaApple, FaSpotify, FaTwitter } from 'react-icons/fa'
import { FiArrowUpRight, FiGlobe, FiInstagram } from 'react-icons/fi'
import { AiOutlineLink, AiFillFacebook } from 'react-icons/ai'
import { IoLogoYoutube } from 'react-icons/io'

import { Slide, toast } from 'react-toastify'

// import { StickyContainer, Sticky } from 'react-sticky';

import LOGO_SPOTIFY from '../img/spotify_logo.png'
import LOGO_APPLE from '../img/apple_music_logo.png'
// import SHARE2X from "../img/SHARE2x.png";

function offset(el) {
  var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

const CuratedBy = props => {
  const { frontmatter } = props.post
  return (
    <div className="columns post-single ui-grid post-curatedby">
      <div className="column is-8 wrapper">
        <div className="columns is-vcentered is-tablet is-multiline">
          <div className="column is-12-tablet component-curatedBy">
            <div className="columns is-mobile is-vcentered curatedBy-row">
              <div className="column is-narrow">
                <img src={frontmatter.curatedByImage} />
              </div>

              <div className="column">
                <p className="cp-title">Curated by</p>
                <p className="cp-value">{frontmatter.curatedByName}</p>
              </div>
            </div>
          </div>

          <div className="column is-12-tablet links">
            {frontmatter.curatedByLinksWebsite && (
              <a
                href={frontmatter.curatedByLinksWebsite}
                data-tip="Website"
                target="_blank"
              >
                <FiGlobe />
              </a>
            )}
            {frontmatter.curatedByLinksSpotify && (
              <a
                href={frontmatter.curatedByLinksSpotify}
                data-tip="Spotify"
                target="_blank"
              >
                <FaSpotify />
              </a>
            )}
            {frontmatter.curatedByLinksApple && (
              <a
                href={frontmatter.curatedByLinksApple}
                data-tip="Apple Music"
                target="_blank"
              >
                <FaApple />
              </a>
            )}
            {frontmatter.curatedByLinksInstagram && (
              <a
                href={frontmatter.curatedByLinksInstagram}
                data-tip="Instagram"
                target="_blank"
              >
                <FiInstagram />
              </a>
            )}
            {frontmatter.curatedByLinksFacebook && (
              <a
                href={frontmatter.curatedByLinksFacebook}
                data-tip="Facebook"
                target="_blank"
              >
                <AiFillFacebook />
              </a>
            )}
            {frontmatter.curatedByLinksTwitter && (
              <a
                href={frontmatter.curatedByLinksTwitter}
                data-tip="Twitter"
                target="_blank"
              >
                <FaTwitter />
              </a>
            )}
            {frontmatter.curatedByLinksYoutube && (
              <a
                href={frontmatter.curatedByLinksYoutube}
                data-tip="YouTube"
                target="_blank"
              >
                <IoLogoYoutube />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props)

    this.playerComponents = {}
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight
  }

  componentDidMount() {
    this.repositionShareBar()
    document.addEventListener('scroll', this.trackScrolling)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling)
    document.body.style.backgroundColor = '#fff'
  }

  trackScrolling = () => {
    this.handlePageBGColor()
    this.repositionShareBar()
  }

  repositionShareBar = () => {
    console.log('repos', window.innerHeight)
    const stickyContainerBottom = this.stickyContainer.getBoundingClientRect()
      .bottom
    const stickyShareBarBottom = this.stickyShareBar
      .querySelector('div.columns')
      .getBoundingClientRect().bottom

    if (typeof window !== 'undefined') {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop

      // console.log("getBoundingClientRect().top", stickyShareBarBottom, stickyContainerBottom)
      if (
        stickyContainerBottom >= stickyContainerBottom &&
        stickyContainerBottom - stickyShareBarBottom < 200
      ) {
        const opacity = Math.max(
          0,
          (stickyContainerBottom - stickyShareBarBottom) / 200
        )
        this.stickyShareBar.style.opacity = opacity
        this.stickyShareBar.style.visibility =
          opacity === 0 ? 'hidden' : 'visible'
      } else {
        // console.log("null")
        this.stickyShareBar.style.position = null
        this.stickyShareBar.style.top = null
        this.stickyShareBar.style.opacity = 1
      }
    }
    // this.stickyShareBar.style.position = "absolute";
    // this.stickyShareBar.style.top = "300px";
  }

  handlePageBGColor = () => {
    const scrolledElements = Object.keys(this.playerComponents).filter(
      playerComponentKey => {
        const playerComponent = this.playerComponents[playerComponentKey]
        const { wrapperElement } = playerComponent

        return this.isBottom(wrapperElement)
      }
    )

    if (scrolledElements.length > 0) {
      const matchingElementKey = scrolledElements[scrolledElements.length - 1]
      const matchingElement = this.playerComponents[matchingElementKey]

      // If user's scrolled through all the players already
      if (
        scrolledElements.length === Object.keys(this.playerComponents).length
      ) {
        if (matchingElement.wrapperElement.getBoundingClientRect().bottom < 0) {
          document.body.style.backgroundColor = '#fff'
          return
        }
      }

      const secondaryColor = matchingElement.props.secondaryColor || 'black'
      document.body.style.backgroundColor = secondaryColor
    } else {
      document.body.style.backgroundColor = '#fff'
    }
  }

  handleShareWindow(url) {
    window.open(
      url,
      '',
      'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=500,height=500'
    )
    return false
  }

  render() {
    const post = this.props.data.mdx
    const { relatedPosts } = this.props.data
    const { frontmatter } = post
    const siteTitle = this.props.data.site.siteMetadata.title
    const {
      previous,
      next,
      applePlaylistTracks,
      spotifyPlaylistTracks,
    } = this.props.pageContext

    const spotifyShareLink =
      'https://open.spotify.com/playlist/' +
      frontmatter.spotifyPlaylistID.replace('spotify:playlist:', '')
    const appleShareLink =
      `https://music.apple.com/playlist/` + frontmatter.applePlaylistID
    const postLink = `https://equemag.com${post.fields.slug}`
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={frontmatter.title}
          description={frontmatter.description}
          image={`https://equemag.com${frontmatter.shareImage || frontmatter.featuredImage}`}
        />

        {/* <StickyContainer>
          <Sticky>
            {({ style }) => ( */}
        <div
          className="container blog-sticky-share"
          ref={div => (this.stickyShareBar = div)}
        >
          <div className="columns">
            <div className="column is-1">
              <a
                href="#"
                data-tip="Copy share link"
                onClick={e => {
                  e.preventDefault()
                  // const tooltipElement = document.querySelector("div.__react_component_tooltip");
                  // tooltipElement.innerHTML = tooltipElement.innerHTML.replace("Copy share link", "Link copied!");

                  copyToClipboard(postLink)

                  toast('Copied to clipboard!', {
                    position: 'bottom-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    type: toast.TYPE.SUCCESS,
                    transition: Slide,
                  })
                }}
              >
                <AiOutlineLink />
              </a>

              <a
                href="#"
                onClick={e => {
                  e.preventDefault()
                  this.handleShareWindow(
                    `https://twitter.com/intent/tweet?text=${frontmatter.title}&url=${postLink}`
                  )
                }}
                data-tip="Twitter"
              >
                <FaTwitter />
              </a>

              <a
                href="#"
                onClick={e => {
                  e.preventDefault()
                  this.handleShareWindow(
                    `https://www.facebook.com/sharer/sharer.php?u=${postLink}`
                  )
                }}
                data-tip="Facebook"
              >
                <AiFillFacebook />
              </a>

              {/* <img src={SHARE2X} /> */}
              <p className="cp-title">Share</p>
            </div>
          </div>
        </div>

        {/* )}
          </Sticky> */}

        <section
          class="hero is-success is-fullheight"
          style={{
            color: frontmatter.postInfoTextColor,
            backgroundColor: frontmatter.postInfoBGColor,
          }}
        >
          <div class="hero-body">
            <div class="container">
              <div className="columns post-single ui-grid post-single-cover">
                <div className="column is-10 aligncenter">
                  <img src={frontmatter.featuredImage} />
                  <p>
                    <b>
                      Listen to the playlist on
                      <div>
                        <a
                          href={spotifyShareLink}
                          target="_blank"
                          data-tip="Spotify"
                        >
                          <FaSpotify />
                        </a>
                        &nbsp;
                        <a
                          href={appleShareLink}
                          target="_blank"
                          data-tip="Apple Music"
                        >
                          <FaApple className="apple" />
                        </a>
                      </div>
                    </b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          className="container sticky-container"
          ref={div => (this.stickyContainer = div)}
        >
          <div className="columns post-single ui-grid post-title">
            <div className="column is-8 aligncenter">
              <h1>{frontmatter.title}</h1>
            </div>
          </div>

          <div className="columns post-single ui-grid post-description">
            <div className="column is-8 aligncenter">
              <p>{frontmatter.description}</p>
            </div>
          </div>

          <div className="columns post-single ui-grid post-share">
            <div className="column is-8 aligncenter">
              <a
                href="#"
                onClick={e => {
                  e.preventDefault()
                  this.handleShareWindow(
                    `https://www.facebook.com/sharer/sharer.php?u=${postLink}`
                  )
                }}
                data-tip="Facebook"
              >
                <AiFillFacebook />
              </a>

              <a
                href="#"
                onClick={e => {
                  e.preventDefault()
                  this.handleShareWindow(
                    `https://twitter.com/intent/tweet?text=${frontmatter.title}&url=${postLink}`
                  )
                }}
                data-tip="Twitter"
              >
                <FaTwitter />
              </a>

              <a
                href="#"
                data-tip="Copy share link"
                onClick={e => {
                  e.preventDefault()
                  // const tooltipElement = document.querySelector("div.__react_component_tooltip");
                  // tooltipElement.innerHTML = tooltipElement.innerHTML.replace("Copy share link", "Link copied!");

                  copyToClipboard(postLink)

                  toast('Copied to clipboard!', {
                    position: 'bottom-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    type: toast.TYPE.SUCCESS,
                    transition: Slide,
                  })
                }}
              >
                <AiOutlineLink />
              </a>
            </div>
          </div>

          <CuratedBy post={post} />

          <div className="post-body">
            <MDXProvider
              components={{
                Player: props => (
                  <div className="columns post-single ui-grid post-player">
                    <div className="column is-10 aligncenter">
                      <Player
                        {...props}
                        applePlaylistTracks={applePlaylistTracks}
                        spotifyPlaylistTracks={spotifyPlaylistTracks}
                        path={this.props.path}
                        ref={playerComponent =>
                          (this.playerComponents[
                            props.trackNumber
                          ] = playerComponent)
                        }
                      />
                    </div>
                  </div>
                ),
                h1: props => (
                  <div className="columns post-single ui-grid post-html-h">
                    <div className="column is-8">
                      <h1>{props.children}</h1>
                    </div>
                  </div>
                ),
                h2: props => (
                  <div className="columns post-single ui-grid post-html-h">
                    <div className="column is-8">
                      <h2>{props.children}</h2>
                    </div>
                  </div>
                ),
                h3: props => (
                  <div className="columns post-single ui-grid post-html-h">
                    <div className="column is-8">
                      <h3>{props.children}</h3>
                    </div>
                  </div>
                ),
                h4: props => (
                  <div className="columns post-single ui-grid post-html-h">
                    <div className="column is-8">
                      <h4>{props.children}</h4>
                    </div>
                  </div>
                ),
                h5: props => (
                  <div className="columns post-single ui-grid post-html-h">
                    <div className="column is-8">
                      <h5>{props.children}</h5>
                    </div>
                  </div>
                ),
                h6: props => (
                  <div className="columns post-single ui-grid post-html-h">
                    <div className="column is-8">
                      <h6>{props.children}</h6>
                    </div>
                  </div>
                ),
                p: props => (
                  <div className="columns post-single ui-grid post-html-p">
                    <div className="column is-8">
                      <p>{props.children}</p>
                    </div>
                  </div>
                ),
              }}
            >
              <MDXRenderer>{post.body}</MDXRenderer>
            </MDXProvider>
          </div>

          <div className="columns post-single ui-grid post-meta">
            <div className="column is-8">
              <div className="columns">
                <div className="column">
                  <p className="meta-title">Posted</p>
                  <p className="meta-value">{frontmatter.date}</p>
                </div>

                <div className="column">
                  <p className="meta-title">Artwork photo by</p>
                  <p className="meta-value">{frontmatter.artworkBy}</p>
                </div>

                <div className="column">
                  <p className="meta-title">Tags</p>
                  <p className="meta-value">{frontmatter.tags.join(', ')}</p>
                </div>
              </div>
            </div>
          </div>

          <CuratedBy post={post} />
        </div>
        {/* </StickyContainer> */}

        <div
          className="container playlist-links"
          ref={div => (this.playlistLinksElement = div)}
        >
          <div>
            <div className="columns">
              <div className="column">
                <a href={spotifyShareLink} target="_blank" className="first">
                  Open the playlist
                  <br />
                  on Spotify <FiArrowUpRight />
                  <br />
                  <img src={LOGO_SPOTIFY} />
                </a>
              </div>

              <div className="column">
                <a href={appleShareLink} target="_blank">
                  Open the playlist
                  <br />
                  on Apple Music <FiArrowUpRight />
                  <br />
                  <img src={LOGO_APPLE} />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="related-posts post-grid">
          <div className="container">
            <h3>You may also like</h3>

            <div className="columns is-mobile is-multiline">
              {relatedPosts.edges.slice(0, 4).map(({ node }) => (
                <div className="column is-12-mobile is-3-tablet is-3-desktop is-3-widescreen is-3-fullhd">
                  <Link to={node.fields.slug}>
                    <img src={node.frontmatter.featuredImage} />
                    <p className="title">
                      <span>{node.frontmatter.title}</span>
                    </p>
                    {node.frontmatter.curatedByName && (
                      <p className="curatedBy">
                        Curated by {node.frontmatter.curatedByName}
                      </p>
                    )}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        curatedByName
        curatedByImage
        curatedByLinksWebsite
        curatedByLinksSpotify
        curatedByLinksApple
        curatedByLinksInstagram
        curatedByLinksFacebook
        curatedByLinksTwitter
        curatedByLinksYoutube
        featuredImage
        shareImage
        postInfoBGColor
        postInfoTextColor
        artworkBy
        spotifyPlaylistID
        applePlaylistID
        tags
      }
      body
    }
    relatedPosts: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { slug: { ne: $slug } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featuredPost
            featuredImage
            curatedByName
          }
        }
      }
    }
  }
`
