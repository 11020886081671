import React from 'react';

import { playData } from '../components/contexts';
import { copyToClipboard } from '../utils/index';

import ReactPlayer from 'react-player'
import { FaApple, FaSpotify, FaTwitter } from 'react-icons/fa'
import { FiArrowUpRight, FiGlobe, FiInstagram } from 'react-icons/fi'
import { AiOutlineLink, AiFillFacebook } from 'react-icons/ai'
import { IoIosPlay, IoIosPause, IoLogoYoutube } from 'react-icons/io'
import { Slide, toast } from 'react-toastify';

import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

export default class extends React.Component {
  state = {
    playing: false,
  }

  handlePlay(trackID) {
    // If another track needs to be pause
    // Pause it
    // And continue playing this track
    if (
      playData &&
      playData.handlePause &&
      playData.currentlyPlaying !== trackID
    ) {
      playData.handlePause();
    }
    playData.handlePause = this.handlePause.bind(this)
    playData.currentlyPlaying = trackID

    this.setState({
      ...this.state,
      playing: true
    }, () => {
      const { props } = this
      const {
        trackNumber,
        applePlaylistTracks
      } = props;
  
      const trackIndex = trackNumber - 1;
      const appleTrack = applePlaylistTracks[trackIndex];
  
      console.log("report", appleTrack)

      trackCustomEvent({
        // string - required - The object that was interacted with (e.g.video)
        category: "Player",
        // string - required - Type of interaction (e.g. 'play')
        action: "play",
        // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
        label: `${appleTrack.attributes.artistName} - ${appleTrack.attributes.name} - ${appleTrack.attributes.id}`,
        // number - optional - Numeric value associated with the event. (e.g. A product ID)
        value: 43
      });
    });
  }

  handlePause() {
    this.setState({
      ...this.state,
      playing: false
    });
  }

  render() {
    const { props } = this
    const {
      path,
      trackNumber,
      applePlaylistTracks,
      spotifyPlaylistTracks,
      primaryColor,
      secondaryColor,
    } = props;

    const trackIndex = trackNumber - 1;
    const appleTrack = applePlaylistTracks[trackIndex];
    const spotifyTrack = spotifyPlaylistTracks[trackIndex];

    const playURL = appleTrack.attributes.previews[0].url;
    const artworkURL = appleTrack.attributes.artwork.url
      .replace('{w}', 400)
      .replace('{h}', 400);

    const { playing, loadedSeconds, playedSeconds } = this.state

    const progressTime = Math.ceil(loadedSeconds - playedSeconds)
    const progressPercentage = playedSeconds / loadedSeconds

    const playerWrapperID = `player-${appleTrack.attributes.name
      .replace(/[^a-z0-9]/gi, '')
      .toLowerCase()}`

    return (
      <>
        <div
          className="player"
          style={{
            backgroundColor: primaryColor || 'black',
          }}
          ref={div => (this.wrapperElement = div)}
          id={playerWrapperID}
          onClick={e => {
            e.preventDefault();

            if (playing) {
              this.handlePause();
            } else {
              this.handlePlay(appleTrack.id);
            }
          }}
        >
          <div className="columns">
            <div className="column is-4 player-cover">
              <div>
                {playing ? <IoIosPause /> : <IoIosPlay className="play" />}
              </div>
              <img src={artworkURL} />
            </div>

            <div className="column is-8 player-info">
              <div className="links">
                <a
                  href={appleTrack.attributes.url}
                  target="_blank"
                  data-tip="Open on Apple Music"
                  onClick={e => e.stopPropagation()}
                >
                  <FaApple />
                </a>
                <a
                  href={`https://open.spotify.com/track/${spotifyTrack.id}`}
                  target="_blank"
                  data-tip="Open on Spotify"
                  onClick={e => e.stopPropagation()}
                >
                  <FaSpotify />
                </a>
                <a
                  href="#"
                  data-tip="Copy share link"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    // const tooltipElement = document.querySelector("div.__react_component_tooltip");
                    // tooltipElement.innerHTML = tooltipElement.innerHTML.replace("Copy share link", "Link copied!");

                    copyToClipboard(
                      `https://equemag.com${path}#${playerWrapperID}`
                    );

                    toast('Copied to clipboard!', {
                      position: "bottom-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      type: toast.TYPE.SUCCESS,
                      transition: Slide
                    });
                  }}
                >
                  <AiOutlineLink />
                </a>
              </div>

              <div className="text">
                <p className="track-num">Track {trackNumber}</p>
                <p className="track-name">{appleTrack.attributes.name}</p>
                <p className="artist-name">
                  {appleTrack.attributes.artistName}
                </p>
              </div>

              {/* <div className="seek">
              <div className="columns is-vcentered">
                <div className="column is-narrow">
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault()

                      if (playing) {
                        this.handlePause()
                      } else {
                        this.handlePlay(appleTrack.id)
                      }
                    }}
                  >
                    {playing ? <IoIosPause /> : <IoIosPlay />}
                  </a>
                </div>
                <div className="column seek-bar">
                  <div className="total-bar">
                    <div
                      className="progress-bar"
                      style={{
                        width: `${progressPercentage * 100}%`,
                      }}
                    ></div>
                  </div>
                </div>
                <div className="column is-narrow progress-seconds">
                  -0:{progressTime < 10 ? `0${progressTime}` : progressTime}
                </div>
              </div>
            </div> */}
            </div>
          </div>

          <ReactPlayer
            url={playURL}
            playing={playing}
            controls={true}
            width="100%"
            height="100%"
            ref={audioPlayer => (this.audioPlayer = audioPlayer)}
            progressInterval={200}
            onProgress={d => {
              const { loadedSeconds, playedSeconds } = d

              if (loadedSeconds === playedSeconds) {
                this.setState({
                  ...this.state,
                  playedSeconds: 0,
                })

                return this.handlePause()
              }

              this.setState({
                ...this.state,
                loadedSeconds,
                playedSeconds,
              })
            }}
            style={{
              display: 'none',
            }}
          />
        </div>
        <div className="component-follow-artist">
          <div className="columns">
            <div className="column is-narrow cp-title">
              Follow {appleTrack.attributes.artistName}
            </div>
            <div className="column cp-value">
              {props.website &&
                <a href={props.website} data-tip="Website" target="_blank">
                  <FiGlobe />
                </a>
              }
              {props.instagram &&
                <a href={`https://instagram.com/${props.instagram}`} data-tip="Instagram" target="_blank">
                  <FiInstagram />
                </a>
              }
              {props.facebook &&
                <a href={`https://facebook.com/${props.facebook}`} data-tip="Facebook" target="_blank">
                  <AiFillFacebook />
                </a>
              }
              {props.twitter &&
                <a href={`https://twitter.com/${props.twitter}`} data-tip="Twitter" target="_blank">
                  <FaTwitter />
                </a>
              }
              {props.youtube &&
                <a href={`https://youtube.com/${props.youtube}`} data-tip="YouTube" target="_blank">
                  <IoLogoYoutube />
                </a>
              }
            </div>
          </div>
        </div>
      </>
    )
  }
}
